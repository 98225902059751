import React, { Component } from 'react';
import './assets/sass/app.scss';
import logo from './assets/images/logo.png';
import axios from 'axios';

export default class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            view: 'text',
            error: false,
            message: ''
        };
    }

    submit = (e) => {
        e.preventDefault();

        const fields = ['name', 'email', 'phone', 'message'];
        const data = fields.reduce((data, field) => ({...data, [field]: e.target[field].value}), {});

        for (const field of fields) {
            if (! data[field]) {
                e.target[field].focus();
                return;
            }
        }

        axios.post('/send-mail.php', data, {headers: {'Content-Type': ''}}).then(({data}) => {
            if (data.success) {
                e.target.reset();
                this.setState({
                    error: false,
                    message: data.message
                });
                return;
            }
            return Promise.reject(data.message);
        }).catch(() => {
            this.setState({
                error: true,
                message: 'Não foi possível enviar sua mensagem no momento.'
            });
        });
    }

    render() {
        const { view, error, message } = this.state;
        return (
            <div className="page wrapper" id="app">
                <div className="container">
                    <header className="header">
                        <div className="social">
                            <div className="label">Acompanhe:</div>
                            <div className="icons">
                                <a href="https://www.facebook.com" className="facebook" target="_blank"
                                   rel="noopener noreferrer">&nbsp;</a>
                                <a href="https://www.instagram.com" className="instagram" target="_blank"
                                   rel="noopener noreferrer">&nbsp;</a>
                            </div>
                        </div>
                    </header>

                    <div className="content">
                        {view === 'form' &&
                            <div className="box form-wrapper">
                                <p className="only-desktop">* campos de preenchimento obrigatório</p>
                                <form onSubmit={this.submit}>
                                    <input type="text" className="form-control" name="name" placeholder="*Digite seu nome"/>
                                    <input type="text" className="form-control" name="email" placeholder="*Digite seu e-mail"/>
                                    <input type="text" className="form-control" name="phone" placeholder="*Digite seu telefone"/>
                                    <textarea name="message" className="form-control" rows="10" placeholder="*Digite sua mensagem" />
                                    <button className="btn-submit" type="submit">ENVIAR</button>
                                    <div className={`message ${error ? 'error' : 'success'}`}>{message}</div>
                                </form>
                            </div>
                        }
                        {view === 'text' &&
                            <div className="box">
                                <div className="text"/>
                                <div className="more-info">
                                    <button className="btn-more-info" onClick={() => this.setState({view: 'form'})}/>
                                    <img src={logo} alt=""/>
                                </div>
                            </div>
                        }
                    </div>

                    <footer className="footer">
                        <p className="copyright">Copyright &copy; 2019 - Yesh Co. <span className="only-desktop-inline">- Desenvolvido por Newmove</span> - Todos os
                            direitos reservados.</p>
                        <a href="http://newmove.com.br/" className="newmove" target="_blank"
                           rel="noopener noreferrer">&nbsp;</a>
                    </footer>
                </div>
            </div>
        );
    }
}
